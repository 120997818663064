.header-middle{
       width:100%;
}
.header-middle img {
    max-width: 300px;
    max-height: 77px;
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
       float: left;
   }
   ul.main-menu>li.menu-item>a {
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 25px;
    padding-bottom: 25px;
    font-size: 17px;
    text-transform: capitalize;
    font-weight: 400;
    color: #312e2e;
    letter-spacing: 0.7px
}
.header-middle {
    padding: 4px 0 10px 0;
}
.yyyview {
    float: right;
    margin-bottom: 37px;
    padding: 17px;
    box-shadow: 0px 0px 17px #ffe8e8;
    border-radius: 17px;
}
.yyyview img {
    width: 160px;
    height: 160px;
    overflow: hidden;
    object-fit: contain;
    object-position: center top;
    border-radius: 100%;
    background-color: #f9f9f9;
    box-shadow: 2px 2px 7px #ccc7c7;
    float: right;
    margin-bottom: 17px;
}
.yyyview h3 {
    display: block;
    text-align: center;
    color: #fa6342;
}
.central-meta {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #ede9e9;
    border-radius: 5px;
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
    padding: 20px;
    position: relative;
}
.adverts-form-required {
    font-weight: 700;
    color: #e9322d;
}
.previewimg{
    max-width: 300px;
}
.aunasss {
    font-size: 17px;
    float: right;
}
.radioqqq {
    display: block;
    float: left;
    background-color: rgb(255, 255, 255);
    padding: 17px 7px 17px 7px;
    margin: 10px 0px 10px 0px;
    min-height: 187px;
    position: relative;
    width: 100%;
}
.radioqqq label {
    color: white;
    font-size: 17px;
}
.radioqqq h2 {
    margin: 0 auto 7px;
    display: block;
    text-align: center;
    color: #fd6500;
    font-size: 23px;
    line-height: 37px;
}
.price {
    margin: 13px auto;
    display: block;
    text-align: center;
}
.radioqqq .radio {
    display: inline;
    opacity: 0;
    width: 0;
    margin: 0;
    overflow: hidden;
    -webkit-appearance: none;
}
.radioqqq .radio__label {
    display: block;
    height: 50px;
    position: relative;
    padding: 10px 13px 15px 48px;
    text-align: right;
    cursor: pointer;
    margin: 0 auto;
    width: 115px;
    font-size: 20px;
    background-color: #edf2f6;
    color: black;
    box-shadow: 1px 1px 5px #707071;
}
.radioqqq .radio__label:before, .radioqqq .radio__label:after {
    position: absolute;
    content: "";
    -webkit-transition: border-color 200ms ease, -webkit-transform 200ms ease;
    transition: border-color 200ms ease, -webkit-transform 200ms ease;
    transition: transform 200ms ease, border-color 200ms ease;
    transition: transform 200ms ease, border-color 200ms ease, -webkit-transform 200ms ease;
    box-sizing: border-box;
}
.radioqqq .radio__label:hover:before {
    border-color: #fb872b;
}
.radioqqq .radio__label:before {
    left: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
    border: 2px solid #dbdbdb;
}
.radioqqq .radio__label:after {
    top: 15px;
    left: 15px;
    width: 20px;
    height: 20px;
    transform: scale(0);
    background-color: #ffffff;
}
.radioqqq .radio__label:hover {
    color: #3c91e6;
}
.radioqqq .radio__label:hover:before {
    border-color: #fb872b;
}
.radioqqq .radio:checked + .radio__label {
    color: rgba(255, 255, 255, 0.87);
    background-color: #4CAF50;
}
.radioqqq .radio:checked + .radio__label:before {
    border-color: #ffffff;
}
.radioqqq .radio:checked + .radio__label:after {
    transform: scale(1);
}

.listing-duration {
    font-size: 15px;
    margin-bottom: 17px;
    text-align: center;
    display: block;
    padding: 0 7px 0 7px;
    color: #615c5c;
    line-height: 27px;
}
.listing-duration2 {
    font-size: 15px;
    margin-bottom: 17px;
    text-align: left;
    display: block;
    padding: 0 7px 0 7px;
    color: #615c5c;
    line-height: 27px;
    height: 77px;
    overflow: hidden;
}
.listing-duration3 {
    font-size: 15px;
    margin-bottom: 17px;
    text-align: left;
    display: block;
    padding: 0 7px 0 7px;
    color: #615c5c;
    line-height: 27px;
    height: 337px;
    overflow: hidden;
}
.listing-duration2:hover {
    overflow-y: scroll;
}
.listing-duration3:hover {
    overflow-y: scroll;
}
.listing-duration3 h1{
    font-size: 20px;
    color: black;
    line-height: 37px;
}
.currency {
    display: inline-block;
    margin-top: 3px;
    vertical-align: top;
    font-size: 23px;
    font-weight: 700;
    color: #fd6500;
}
.value {
    font-size: 27px;
    font-weight: 300;
    margin-left: 3px;
}
.duration {
    font-size: 1.4rem;
    color: #fd6500;
    display: none;
}
.duration::before {
    content: '/';
    margin-right: 2px;
}
.pagination {
    margin-top: 25px;
}
.pagination span {
  cursor: pointer;
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
}

.pagination span.active {
    background-color: #f67a3d;
    color: white;
    border: 1px solid #f04501;
}
body{
    font-family: 'Roboto Slab', sans-serif;
}
h1,h2,h3,h4,h5,h6{   
    font-family: 'Roboto Slab', sans-serif;
}
p { 
    font-family: 'Roboto Slab', sans-serif;
}
.create-post {
    border-bottom: 1px solid #e6ecf5;
    display: inline-block;
    font-weight: 400;
    font-size: 20px;
    line-height: 37px;
    letter-spacing: 1px;
    margin-bottom: 47px;
    padding-bottom: 12px;
    text-transform: capitalize;
    width: 87%;
    color: #515365;
    position: relative;
}
.create-post::before {
    content: "";
    height: 90%;
    left: -20px;
    position: absolute;
    top: -5px;
    width: 3px;
    background: #fa6342;
}
.text-warning2{
    border-bottom: 1px solid #e6ecf5;
    display: block;
    font-weight: 500;
    font-size: 23px;
    line-height: 15px;
    margin-bottom: 20px;
    padding-bottom: 12px;
    text-transform: capitalize;
    width: 100%;
    color: #515365;
    position: relative;
    clear: both;
}
.text-warning2::before {
    content: "";
    height: 90%;
    left: -20px;
    position: absolute;
    top: -5px;
    width: 3px;
    background: #fa6342;
}
.central-metazzz {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #ede9e9;
    border-radius: 5px;
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
    padding: 37px 27px 27px 27px;
    position: relative;
}
.central-metazzzmmm {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #ede9e9;
    border-radius: 5px;
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
    padding: 37px 27px 27px 27px;
    position: relative;
    background-image: url(./Img/home-background-img.jpg);
    background-size: cover;
    background-position: center 0px;
    background-repeat: no-repeat;
    background-attachment: initial;
}
.addbooksssmmm {
    padding: 60px 0 60px 0;
    background-color: white!important;
}
.redmoesss {
    margin-top: 47px;
    max-height: 200px;
    overflow: hidden;
    border-top: 2px solid #efefef;
    padding: 37px;
    border-radius: 10px;
}
.redmoesss p {
    font-family: 'Roboto', sans-serif;
    line-height: 37px;
    font-size: 18px;
    color: #565252;
}
.redmoesssqqq {
    max-height: unset;
}
.redmoesssmmm{
    color: #fa6342;
    cursor: pointer;
}
.zzz {
    background: #fa6342;
    float: right;
    border: 0;
    padding: 7px 17px 7px 17px;
    color: white;
    border-radius: 7px;
    font-size: 17px;
}
.zzz:hover {
    background: linear-gradient(180deg,#cfd74e 0,#98c06a 0,#cfd74e 99%);
    color: #ff4242;
    text-decoration: none;
    text-shadow: 0 0 3px white;
}
.zzz2{
    float: left;
} 
.zzz2 .svg-inline--fa {
    height: 33px;
    color: #ffffff;
    vertical-align: middle;
    margin-right: 10px;
    background-color: #fa6342;
    padding: 7px 7px 7px 7px;
    width: 33px!important;
    border-radius: 7px;
}
.zzz2 h3 {
    font-size: 16px;
    margin-bottom: 17px;
    color: #515365;
    text-transform: capitalize;
    line-height: 37px;
}
.zzz3 h6 {
    margin: 27px auto 0;
    font-size: 18px;
    border-top: 1px solid #e6ecf5;
    border-bottom: 1px solid #e6ecf5;
    padding: 17px 0 17px 0;
    text-align: center;
}
.zzz3 span{
    color: #fd6500;
}
.zzz4 img {
    max-width: 107px;
    margin-left: 10px;
    margin-top: -6px;
}
.zzz4 .col-md-12{
    padding: 0px;
}
.continueButton {
    background-color: #fd6500;
    color: white;
    padding: 10px 17px 10px 17px;
    font-size: 20px;
    outline: none;
    border: 0;
    border-radius: 7px;
    line-height: 27px;
    margin-top: 17px;
    float: left;
}
.continueButton:hover {
    background: linear-gradient(180deg,#cfd74e 0,#98c06a 0,#cfd74e 99%);
    color: #ff4242;
    text-decoration: none;
    text-shadow: 0 0 3px white;
}

.addbooksss .zzz4 label {
    color: #667aea;
    display: inline-block;
    font-size: 18px;
    margin-bottom: 7px;
    width: auto;
    font-weight: 700;
    text-transform: capitalize;
    margin-right: 17px;
}
.zzz4 input[type="radio"] {
    position: absolute;
    opacity: 0;
}
.zzz4 input[type="radio"]:checked + .radio-labelpay:before {
    background-color: #62ab00;
    box-shadow: inset 0 0 0 4px #f4f4f4;
}
.zzz4 input[type="radio"] + .radio-labelpay:before {
    content: '';
    background: #f4f4f4;
    border-radius: 100%;
    border: 1px solid #62ab00;
    display: inline-block;
    width: 1.4em;
    height: 1.4em;
    position: relative;
    top: -0.2em;
    margin-right: 0em;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    -webkit-transition: all 250ms ease;
    transition: all 250ms ease;
}

.zzz4 {
    padding: 7px 0 10px 0;
    float: left;
}
.addbooksss .previewimg {
    max-width: 137px;
    margin-bottom: 17px;
}
.stripesss {
    padding: 60px 0px 60px 0px;
}
.stripesss label {
    color: #515365;
    display: inline-block;
    font-size: 16px;
    margin-bottom: 7px;
    width: 100%;
    font-weight: 500;
    text-transform: capitalize;
}
.stripesss .form-control {
    display: block;
    font-weight: 400;
    line-height: 1.5;
    background-color: #fff;
    background-clip: padding-box;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    background: #edf2f6 none repeat scroll 0 0;
    border-radius: 4px;
    font-size: 16px;
    padding: 14px 15px;
    width: 100%;
    color: #535165;
    border: 1px solid #e4e4e4;
    height: auto;
    margin-bottom: 27px;
}
legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 27px;
    font-size: 1.5rem;
    line-height: inherit;
    white-space: normal;
    clear: both;
    color: #fd6500;
}
.btn-successqqq {
    color: #2f2b2b;
    background-color: #dad7d7;
    border-color: #f5f5f5!important;
    padding: 7px 17px 7px 17px!important;
    width: auto!important;
    font-size: 17px!important;
    margin: 0 auto;
    display: table!important;
}
.card-errors {
    float: left;
    width: 100%;
}
.card-errors p {
    font-size: 17px;
    border: 1px dashed;
    padding: 10px;
    color: #EA4335;
}
.loaderbuttonsss{
    color:#00BFFF;
     height:77px;
     width:77px;
     float: right;
     margin-right: 37px;
}
.loaderbuttonsss2 {
    color: #00bfff;
    height: 77px;
    width: 100%;
    float: right;
    margin-right: 0;
    display: block;
    clear: both;
    text-align: right;
}
.loaderbuttonsss2 svg {
    fill: #4caf50;
}
.btn-primary2{
    background-color: #e65a20;
    color: white;
    padding: 7px 17px 7px 17px;
    margin-top: 17px;
}
.canqqq{
    margin-top: 17px;
    margin-left: 17px;
}
.successs {
    margin: 77px 0 77px 0;
    height: 100vh;
}
.successs h3 {
    color: #ffffff;
    text-align: center;
    width: 50%;
    margin: 0 auto;
    line-height: 67px;
    border: 1px solid lavender;
    padding: 37px;
    background-color: #f57332;
}
.successs a{
    text-align: center;
    margin: 47px auto;
    display: table;
    color: #ffffff;
    font-size: 24px;
    background-color: #79c525;
    padding: 10px;
    border-radius: 7px;
}
.videosss {
    width: 577px;
    height: 377px;
}
.firstsectionsss {
    position: relative;
    z-index: 0;
}
.books{
    margin: 0 auto;
    display: block;
    position: relative;
}
.books img{
    width: 237px;
    height: 367px;
    overflow: hidden;
    object-fit: cover;
    -o-object-position: center center;
    object-position: center center;
    margin: 0 auto;
    display: block;
}
.nav__list {
    display: block;
    float: left;
    width: 100%;
    box-shadow: 0 1px 2px rgba(0,0,0,.12), 0 1px 2px transparent;
    padding: 10px 17px 0 17px;
    margin: 0px auto 27px;
    border-radius: 20px;
    height: 47px;
    overflow: hidden;
}
.selected {
    color: #62ab00;
}
.nav__item {
    position: relative;
    transition: all .45s cubic-bezier(.23,1,.32,1);
    margin-right: 37px;
    display: inline-block;
}
.nav__list li:last-child{
    margin-right: 0px;
}
.nav__label {
    font-size: 18px;
    line-height: 1.1;
    text-align: center;
    font-weight: 400;
    margin-bottom: 10px;
    color: black;
    font-family: 'Roboto Slab', sans-serif;
}
.nav__label img {
    width: 27px;
    height: 27px;
    border-radius: 100%;
    margin-right: 7px;
    object-fit: cover;
    -o-object-position: center right;
    object-position: center right;
}
.packbooksss h4 {
    font-size: 17px;
    height: 57px;
    overflow: hidden;
    width: 100%;
    text-align: center;
    margin: 0 auto 17px;
    font-weight: 500;
    line-height: 27px;
    color: #353535;
    padding: 0 7px;
    font-family: 'Roboto', sans-serif;
}
.bestseller-content h2 {
    color: #62ab00;
    font-size: 30px;
    line-height: 1.1;
    font-weight: 400;
    margin-bottom: 27px;
    margin-top: 10px;
}
.bestseller-content ul {
    padding: 0;
    margin: 17px 0 0 0;
}
.bestseller-content li {
    list-style: none;
    display: inline-block;
    margin-right: 12px;
}
.bestseller-content li img {
    width: 27px;
    margin: 13px auto;
    display: block;
    border-radius: 6px;
}
.hhselss {
    height: 237px;
    overflow: auto;
    clear: both;
    margin-top: 27px;
    display: block;
    float: left;
    visibility: hidden;
}

.hhselsss {
    visibility: visible;
}
.hhselss:hover {
    visibility: visible;
}
.bestseller-content h1 {
    margin-bottom: 30px;
    position: relative;
    text-transform: uppercase;
    display: block;
    line-height: 30px;
    color: #222;
    font-weight: 600;
    font-size: 25px;
    float: left;
    width: 100%;
    letter-spacing: 1.2px;
}
.bestseller-content .col-md-6, .bestseller-content .col-md-12, .bestseller-content .col-md-5, .bestseller-content .col-md-7{
    padding: 0;
}
.books2 img {
    width: auto;
    height: 193px!important;
    margin: 0 auto;
    display: block;
}
.yyy img {
    margin: 0 auto;
    display: block;
    width: 160px;
    height: 160px;
    overflow: hidden;
    object-fit: contain;
    object-position: center top;
    border-radius: 100%;
    background-color: #f9f9f9;
    box-shadow: 2px 2px 7px #ccc7c7;
}
.books3 img {
    width: 237px;
    height: 367px;
    overflow: hidden;
    object-fit: cover;
    object-position: center center;
    float:right;
}
p {
    font-family: 'Roboto', sans-serif;
    line-height: 33px;
    font-size: 16px;
    color: #565252;
}
.bestseller-content {
    margin: 0 0 0 0;
}
.yyybuttons {
    float: left;
    background-color: #fd6500;
    color: white;
    padding: 7px 17px 7px 17px;
    font-size: 16px;
    border-radius: 7px;
    margin-bottom: 17px;
    margin-right: 17px;
}
.yyybuttons2 {
    float: right;
    background-color: #fd6500;
    color: white;
    padding: 7px 17px 7px 17px;
    font-size: 16px;
    border-radius: 7px;
    margin-bottom: 17px;
}
.clearfix{
    clear: both;
}
.secreccc {
    padding: 60px 0px 17px 0px;
    width: 100%;
    float: left;
    background-image: url(./Img/sqssqsqsss.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
    margin: 0px 0px 17px 0px;
}
.secreccc h1 {
    text-align: left;
    font-size: 18px;
    line-height: 33px;
    float: left;
    padding: 7px 0 0 0;
}
.booksrec {
    position: relative;
    height: 247px;
    float: left;
    margin-left: 27px;
    margin-top: 30px;
}
.secreccc .booksrec img {
    width: auto;
    height: 247px;
    overflow: hidden;
    object-fit: cover;
    object-position: center center;
    margin: 0 auto 17px;
    display: block;
    box-shadow: 3px 3px 10px #6d6b6b;
}
.section-title--bordered {
    position: relative;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 17px;
}
.secreccc .section-title h2 {
    position: relative;
    text-transform: capitalize;
    margin: 0;
    display: inline-block;
    vertical-align: top;
    line-height: 30px;
    color: #f2eeed;
    font-weight: bold;
    font-size: 50px;
    margin-bottom: 17px;
    letter-spacing: 7px;
    font-family: 'Great Vibes', sans-serif;
    text-shadow: 2px 2px 3px #ffffff;
    text-shadow: 0 0 9px black;
}
.section-title h2 {
    position: relative;
    text-transform: capitalize;
    display: block;
    vertical-align: top;
    line-height: 30px;
    color: #5a5757;
    font-weight: 600;
    font-size: 23px;
    margin-bottom: 10px;
    text-align: left;
}
.cccgggsss{
    display: block;
    height: auto;
    overflow: auto;
    float: left;
    width: 100%;
    text-align: left;
    color: #62ab00;
}
.yyybuttons3{  
    background-color: #fd6500;
    color: white;
    padding: 7px 17px 7px 17px;
    font-size: 16px;
    border-radius: 7px;
    margin: 17px auto 0;
    display: table;
}
.single-btn{
    max-height: 317px;
    overflow: hidden;
    margin-bottom: 37px;
    background-color: white;
    padding: 17px;
}
.booksrec::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 7px;
    border-right: 1px solid #000;
    background-color: rgba(0,0,0,.15);
    border-radius: 7px 0 0 7px;
    -webkit-filter: blur(.1em);
    filter: blur(.1em);
}
.booksrec2{
    overflow:hidden;
}
.booksrec2 img {
    width: auto;
    height: 137px;
    overflow: hidden;
    object-fit: cover;
    object-position: center center;
    margin: 0px 0px 7px 0px;
    display: block;
    box-shadow: 0 0 3px #777570;
}
.menu-item {
    list-style: none;
}
.menu-item a{
    color: #0c0c0c;
    font-size: 17px;
}
.menu-item a:hover{
    color: #f35202;
}
.secreccc2{
    position:relative;
}
.booksrec2 .hover-btns {
    position: absolute;
    opacity: 0;
    top: 55%;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
    margin: 0 auto;
    padding: 7px 13px 7px 13px;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 3;
    border-radius: 3px;
    box-shadow: 0 3px 5px 0 rgba(0,0,0,.15);
    transition: all .3s ease-in-out;
    display: inline-block;
    color: #f35202;
    box-shadow: 2px 2px 7px #fd6500;
}
.booksrec2:hover .hover-btns {
    opacity: 1;
    top: 50%;
    color: #98c06a;
    box-shadow: 2px 2px 7px #98c06a;
}
.single-btn2 {
    margin-left: 17px;
    max-height: 147px;
    overflow: hidden;
    margin-top: 3px;
}
.description h6{
    color: black;
}
.single-btn2 h6{
    line-height: 27px;
    margin-bottom: 0;
}
a {
    color: #2370d6;
}
.description {
    float: left;
    margin-top: 0;
    width: 100%;
    text-align: left;
    border-bottom: 1px solid #e6e6fa;
    margin-bottom: 17px;
    padding-bottom: 17px;
}
.videosss2 {
    width: 92%;
    height: 207px;
    margin: 27px auto 17px;
    display: block;
    position: relative;
    box-shadow: 0px 8px 16px -6px, 0px 0px 16px -6px;
    color: #000000;
    border-radius: 10px;
}
.secreccc2 {
    margin: 0 0 77px 0;
}
@media(min-width:992px){  
.secreccc3 .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 19.666667%;
    max-width: 19.666667%;
}
}
.booksrec3 img {
    max-height: 247px;
}
.secreccc3qqq {
    position: relative;
    background-image: url(./Img/slide1.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 60px 0 60px 0;
}
.secreccc3{
    position: relative;
}
.listqqq {
    float: right;
    font-size: 16px;
    color: #467d06!important;
}
.stansss2 {
    height: 377px;
    overflow: hidden;
    background-color: #ffffff;
    padding: 17px 10px 17px 10px;
    margin: 17px 10px 0px 10px;
    border: 2px solid #ffffff;
    border-radius: 0px 0px;
    box-shadow: 0 0 13px 0px rgba(0,0,0,0.06);
    background: linear-gradient(137deg, #ffffff 65%, #e2e2e2 107%) repeat scroll 0 0;
}
.section-title2{
    width: 100%;
    float: left;
    position: relative;
    display: block;
}
.section-title2 h2 {
    position: relative;
    text-transform: uppercase;
    display: block;
    vertical-align: top;
    line-height: 30px;
    color: #fd6500;
    font-weight: 600;
    font-size: 33px;
    margin-bottom: 17px;
    text-align: center;
    font-style: oblique;
    letter-spacing: 4px;
}

.product-detail{
    position: relative;
    text-align: center;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e4e4e4;
    font-family: Spicy Rice;
    letter-spacing: 0.05em;
    color: #be9c79;
    display: inline-block;
    width: 100%;
    margin: 0px 0 10px;
    line-height: normal;
}
.product-detail:after {
    clear: both;
    content: "";
    display: inline-block;
    height: 10px;
    margin: 0 auto;
    position: absolute;
    bottom: -4px;
    left: 0;
    right: 0;
    text-align: center;
    width: 10px;
    border-radius: 1px;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    -ms-border-radius: 1px;
    -o-border-radius: 1px;
    box-shadow: 0 0 0 8px #ffffff;
    -webkit-box-shadow: 0 0 0 8px #ffffff;
    border-radius: 1px;
    background: #84c13c;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.section-title3 {
    width: 100%;
    float: left;
    position: relative;
    display: block;
}
.section-title3 h2 {
    position: relative;
    text-transform: uppercase;
    display: block;
    vertical-align: top;
    line-height: 30px;
    color: #fd6500;
    font-weight: 500;
    font-size: 27px;
    margin-bottom: 7px;
    text-align: left;
    letter-spacing: 3px;
}
.stansss2 h6 {
    color: #4d8a05;
    margin: 0 auto 7px;
    text-align: center;
    font-size: 16px;
    letter-spacing: 0.7px;
    height: 16px;
    overflow: hidden;
}
.stansss2 h4 {
    margin: 0px 0 7px 0;
    text-align: center;
    font-size: 17px;
    height: 22px;
    overflow: hidden;
    width: 100%;
    display: block;
    clear: both;
    float: left;
}
.stansss2 p {
    text-align: center;
    margin: 0px 0 0px 0;
    font-size: 18px;
    color: #f35202;
}
.trailsssecqqq {
    padding: 17px 0px 0px 0px;
    position: relative;
    margin: 60px 0 17px 0;
}
.videossspagsss2 {
    margin-bottom: 27px;
    height: 307px;
    overflow: hidden;
}
.videossspagsss2 .videosss2 {
    margin: 0px auto 17px;
}
.trailsssecqqq .section-title2 {
    z-index: 7;
}
.trailsssecqqq .bg-overlay {
    background: rgba(255, 255, 255, 0.35);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 3;
}
.trailsssecqqq::after {
    content: "";
    bottom: 0;
    display: block;
    position: absolute;
    margin-bottom: 0;
    height: 100%;
    width: 100%;
    background-image: url(./Img/dot.png)!important;
    background-position: 0 0px!important;
    background-repeat: repeat!important;
    left: 0;
    z-index: 0;
}
.trailsssecqqq .col-md-2 {
    z-index: 7;
}
.latestbooks2qqq {
    border: 1px solid #f5f1ed;
    margin-bottom: 17px;
    background-color: #fbfbfb;
    padding: 17px;
    background-image: url(./Img/featured-author-bg.jpg)
}
.latestbooks2qqq h6{
    text-align: center;
    color: #98c06a;
    margin-bottom: 17px;
}
.addbooksssmmmq {
    padding: 60px 0 60px 0;
    float: left;
}
.addbooksssmmmq .addbooksss {
    padding: 27px;
    float: left;
    background-color: white;
}
.booksrec3 {
    position: relative;
    overflow: hidden;
    float: left;
    width: 100%;
}
.booksrec3 .hover-btns {
    position: absolute;
    opacity: 0;
    top: 55%;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    padding: 7px 13px 7px 13px;
    left: 0;
    right:0;
    background: #fff;
    z-index: 3;
    border-radius: 3px;
    box-shadow: 0 3px 5px 0 rgba(0,0,0,.15);
    transition: all .3s ease-in-out;
    display: block;
}
.booksrec3:hover .hover-btns {
    opacity: 1;
    top: 50%;
}
.booksrec4 img {
    max-height: 227px;
    overflow: hidden;
    margin: 0 auto;
    display: block;
    box-shadow: -3px 1px 6px #5f5d5d;
    border-radius: 10px;
}
.packbooksss {
    max-height: 537px;
    overflow: hidden;
}
.books2 {
    position: relative;
    height: 193px;
    float: left;
    margin-left: 27px;
    margin-bottom: 7px;
}
.books2::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 7px;
    border-right: 1px solid #000;
    background-color: rgba(0,0,0,.15);
    border-radius: 7px 0 0 7px;
    filter: blur(.1em);
}
.booksrec4 {
    position: relative;
    overflow: hidden;
    float: left;
    width: 100%;
}
.booksrec4 .hover-btns {
    position: absolute;
    opacity: 0;
    top: 55%;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
    margin: 0 auto;
    padding: 7px 13px 7px 13px;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 3;
    border-radius: 3px;
    box-shadow: 0 3px 5px 0 rgba(0,0,0,.15);
    transition: all .3s ease-in-out;
    display: inline-block;
    color: #f35202;
    box-shadow: 2px 2px 7px #fd6500;
}
.booksrec4:hover .hover-btns {
    opacity: 1;
    top: 50%;
    color: #98c06a;
    box-shadow: 2px 2px 7px #98c06a;
}
.latestbooks2qqq .book-wrapper img {
    max-width: 177px;
    height: 197px;
}
.audiosssbbb {
    height: 367px;
    overflow: hidden;
    margin-bottom: 17px;
    border-right: 1px solid #e0e0e4;
}
.pagssspending {
    padding: 0px 0px 60px 0px;
}
.pagssspending img {
    margin: 0 auto 57px;
    display: block;
    width: 100%;
}
.pagssspending .single-btnmmm {
    border: 1px solid #e6e6fa;
    padding: 11px 10px 10px 10px;
    border-radius: 7px;
    float: right;
    margin-right: 33px;
    color: #ffffff;
    background-color: #f67d41;
}
.pagssspending .quicskkssspubsss {
    padding: 27px 17px 0 17px;
    height: 387px;
}
.pagssspending .videossspagsss {
    margin-bottom: 27px;
    height: 337px;
    overflow: hidden;
}
.pagssspending .videossspagsss h2 {
    font-size: 17px;
    height: 27px;
    overflow: hidden;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0;
}
.pagssspending .videosss2 {
    margin: 10px auto 17px;
}

.stansss2:hover {
    border: 2px solid #98c06a;
    transition: ease;
    transition-duration: .4s;
    transition-delay: .20s;
    background-color: #f7f7f7;
}
.booksrec3 {
    height: 247px;
    margin-left: 20px;
}
.booksrec3::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 7px;
    border-right: 1px solid #000;
    background-color: rgba(0,0,0,.15);
    border-radius: 7px 0 0 7px;
    filter: blur(.1em);
}
.books3list {
    overflow: hidden;
    display: inline-block;
    float: left;
    position: relative;
    max-height: 100px;
    margin-left: 0;
    margin-bottom: 0;
}
.books3list::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 7px;
    border-right: 1px solid #000;
    background-color: rgba(0,0,0,.15);
    border-radius: 7px 0 0 7px;
    filter: blur(.1em);
}
.books3list img {
    width: auto;
    max-height: 87px;
    overflow: hidden;
    display: block;
    float: left;
}
.latestbooks {
    padding: 10px 10px 0px 10px;
    box-shadow: -3px 0px 7px #fbdac3;
    background-color: white;
}
.latestbooks2 {
    padding: 0px;
    background-color: white;
    height: 97px;
    overflow: hidden;
    margin-bottom: 10px;
    border-bottom: 1px solid lavender;
}
.latestbooks h4 {
    font-size: 14px;
    line-height: 20px;
    color: #515250;
    margin: 0;
    font-weight: 600;
    height: 37px;
    overflow: hidden;
    padding: 0;
}
.latestbooks p{
    color: #fd6500;
    font-weight: 700;
}
.latestbooks h6 {
    color: #54900f;
    margin: 0px 0 7px 0;
    font-size: 14px;
}
.books .hover-btns {
    position: absolute;
    opacity: 0;
    top: 55%;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    padding: 7px 13px 7px 13px;
    left: 0;
    right:0;
    background: #fff;
    z-index: 3;
    border-radius: 3px;
    box-shadow: 0 3px 5px 0 rgba(0,0,0,.15);
    transition: all .3s ease-in-out;
    display: block;
}
.books:hover .hover-btns {
    opacity: 1;
    top: 50%;
}
.books2 .hover-btns {
    position: absolute;
    opacity: 0;
    top: 55%;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    padding: 7px 13px 7px 13px;
    left: 0;
    right:0;
    background: #fff;
    z-index: 3;
    border-radius: 3px;
    box-shadow: 0 3px 5px 0 rgba(0,0,0,.15);
    transition: all .3s ease-in-out;
    display: inline-block;
    color: #f35202;
    box-shadow: 2px 2px 7px #fd6500;
}
.books2:hover .hover-btns {
    opacity: 1;
    top: 50%;
}
.books2 .hover-btns:hover{
    color: #98c06a;
    box-shadow: 2px 2px 7px #98c06a;
}
.stansss {
    height: 317px;
    overflow: hidden;
    background-color: #04030300;
    padding: 17px 10px 17px 10px;
    margin: 17px 10px 10px 10px;
    border: 3px solid #ffffff;
    border-radius: 0px 0px;
    box-shadow: 0 0 13px 0px rgba(0,0,0,0.06);
    background: linear-gradient(120deg, #f3e4da 8%, #ffffff 50%) repeat scroll 0 0;
}
.standardsss .section-title2::after {
    content: "";
    bottom: 0;
    display: block;
    position: absolute;
    margin-bottom: 0;
    height: 100%;
    width: 100%;
    background-image: url(./Img/dot.png)!important;
    background-position: 0 0px!important;
    background-repeat: repeat!important;
    left: 0;
    z-index: 0;
}
.standardsss .section-title2 h2 {
    position: relative;
    text-transform: uppercase;
    display: block;
    vertical-align: top;
    line-height: 30px;
    color: #000000;
    font-weight: 600;
    background: linear-gradient(to right, #f18842 0%, #ef4705 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 47px;
    margin-bottom: 17px;
    text-align: center;
    letter-spacing: 4px;
    z-index: 7;
}
.copyrights{
    position: relative;
    width: 100%;
    float: left;
    padding: 4px 0px 4px 0px;
    background-color: #34465e;
}
.copyrights p{
    margin: 0;
}
.green{
    color: #fd6500;
}
.white{
    text-align: center;
    display: block;
    color: white;
}
.footer{
    width: 100%;
    float: left;
    z-index: 999;
    background-color: #f7f7f7;
    box-shadow: 0 0 17px #e2d5d5;
    padding: 60px 0 60px 0;
}
.footer h3 {
    margin-bottom: 27px;
}
.footer ul {
    padding: 0;
}
.footer li {
    margin: 0 0 17px 0;
    list-style: none;
}
.footer .svg-inline--fa {
    height: 27px;
    vertical-align: middle;
    margin-right: 6px;
    color: #f04401;
}
.footer a {
    color: #000000;
}
.pagsss{
    padding:0px 0px 60px 0px;
}
.pagsss img {
    height: 300px;
    margin: 0 auto 57px;
    display: block;
}
.pagsssdesccc{
    max-height: 267px;
    overflow: hidden;
}
.single-btnmmm{
    border: 1px solid #e6e6fa;
    padding: 3px 10px 3px 10px;
    border-radius: 7px;
    float: right;
    margin-right: 33px;
    color: #62ab00;
}
.single-btnmmm:hover {
    background-color: #62ab00;
    color: #fff;
}
.videossspagsss {
    margin-bottom: 27px;
    height: 307px;
    overflow: hidden;
}
.videossspagsss h2{
    font-size: 17px;
}
.videossspagsss h3 {
    color: #000000;
    font-size: 20px;
    font-family: Roboto,sans-serif;
    line-height: 27px;
    text-align: center;
}
.quicskkssspubsss {
    width: 100%;
    float: left;
    border: 1px solid #e6e6fa;
    padding: 17px 10px 0 10px;
    border-radius: 17px;
    box-shadow: 0 0 7px #00000026;
    height: 467px;
    overflow: hidden;
    margin-bottom: 47px;
    position: relative;
}
.quicskkssspubsss h2 {
    text-align: left;
    margin-left: 17px;
    color: #f04501;
    font-size: 23px;
    font-family: Roboto,sans-serif;
    line-height: 27px;
    margin-bottom: 20px;
}
.waestae2 {
    height: 217px;
    overflow: hidden;
}
.waestae2 h3{
    margin-bottom: 0px!important;
}
.waestae {
    height: 177px;
    overflow: hidden;
}
.basccsss3 {
    padding: 0;
    height: 227px;
    overflow: hidden;
    border-bottom: 1px solid #e6e6fa;
    margin-bottom: 47px;
}
.basccsss3 p {
    font-size: 16px;
    color: #403c3c;
    margin-bottom: 0;
    line-height: 23px;
}
.basccsss2 img {
    box-shadow: -6px -2px 12px #0000005c;
}
.pagsssdescccmmm {
    max-height: 177px;
    overflow: hidden;
}
.pagsssdescccmmm h3 {
    color: #f04501;
    font-size: 20px;
    font-family: Roboto,sans-serif;
    line-height: 27px;
}
.pagsssdescccmmm b, strong {
    font-weight: bolder;
    font-size: 15px;
}
.quicskkssspubsss img{
    max-height: 207px;
    box-shadow: -11px 1px 15px #cecece;
}
.quicskkssspubsss .viewsss {
    position: absolute;
    bottom: 0%;
    right: 0%;
    left: 2%;
    color: #62ab00;
    background-color: white;
    width: 100%;
    display: block;
    padding: 10px 17px 10px 17px;
    font-size: 18px;
}
.quicskkssspubsss .viewsss:hover{ 
    color: #f04501;
}
.pagsss2 {
    padding: 0px 0px 60px 0px;
}
img {
    max-width: 100%;
    height: auto;
}
.basccsss2 {
    width: 100%;
    float: left;
    border: 1px solid #e6e6fa;
    padding: 27px 27px 27px 27px;
    border-radius: 17px;
    box-shadow: 0 0 7px #b9b7b745;
    height: 397px;
    overflow: hidden;
    margin-bottom: 47px;
}
.bestseller-content2 img {
    width: 22%;
    float: left;
    margin-right: 7%;
}
.bestseller-content2 h2 {
    color: #62ab00;
    font-size: 38px;
    line-height: 1.3;
    text-align: left;
    font-weight: 400;
    width: 44%;
    display: inline-block;
    margin-top: 37px;
    margin-bottom: 0;
}
.basccsss2 .hhselsss{
    word-break: break-all;
    color: #4c4747;
    line-height: 37px;
}
.bestseller-content2 .hhselss {
    height: 187px;
    overflow: auto;
    visibility: hidden;
    margin-top: 17px;
}
.yyyviewimgsss {
    width: 160px;
    height: 160px;
    overflow: hidden;
    object-fit: contain;
    object-position: center top;
    border-radius: 7%;
    background-color: #f9f9f9;
    box-shadow: 2px 2px 7px #ccc7c7;
    margin: 17px auto 17px;
    display: block;
}
.addbookssspacks .create-post {
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.7px;
    height: 37px;
    overflow: hidden;
}
.addbookssspacks .central-metazzz {
    margin-bottom: 20px;
    padding: 10px 10px 10px 10px;
}
.addbookssspacks .book-wrapper img {
    max-width: 137px;
    height: 177px;
}
.addbookssspacks .zzz2nnn {
    float: left;
    height: 187px;
    overflow: hidden;
}
.packvideosssnnn {
    margin: 27px 0 27px 0;
}
.packvideosssnnn h3{
    font-size: 17px;
    margin-bottom: 0;
    text-align: center;
}
.packvideosssnnn .btn {
    padding: 3px 10px 3px 10px;
}
.srfefdsgbfedv{
    background-color: white;
    padding: 17px;
    width: 100%;
    float: left;
}
.zzz2nnn h3 {
    font-size: 16px;
    margin-bottom: 7px;
    color: #fd6500;
    text-transform: capitalize;
    line-height: 37px;
    font-weight: 400;
}
.avatar-previewqqq {
    width: 100%;
    display: block;
    float: left;
    background-color: white;
    padding: 10px;
}
.avatar-previewqqq h6{
    text-align: center;
    color: #f67738;
    width: 100%;
    display: block;
    clear: both;
    font-size: 17px;
}
.addbookssspacks {
    padding: 0px 0 60px 0;
}
.hhselsss {
    visibility: visible;
}
.hhselss:hover {
    visibility: visible;
}
.dropdown {
    position: relative;
    width: 100%;
    float: left;
    padding: 4px 0px 4px 0px;
    border-bottom: 1px solid #ededed;
    background-color: #34465e;
}
.dropdown ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
    z-index: 1;
    border: 2px solid #eee;
    position: absolute;
    background-color: white;
}
.dropdown li a {
    padding: 7px 0px 7px 0px;
    color: #555;
    line-height: 26px;
    text-transform: capitalize;
    font-weight: 400;
    display: block;
}
.dropdown li {
    padding: 8px 16px;
    border-bottom: 1px solid #e5e5e5;
}
.dropdown li:last-child {
    border-bottom: none;
}
.dropdown li:hover {
    background-color: #e5e5e5;
    color: white;
}
a:hover {
    text-decoration: none;
}
.headsesssbutton {
    position: relative;
    line-height: 26px;
    border: solid 1px #ededed;
    float: left;
    min-width: 227px;
}
.button {
    color: #403c3c;
    text-decoration: none;
    display: block;
    font-weight: 400;
    padding: 3px 7px 3px 7px;
    letter-spacing: 1px;
    font-size: 15px;
    cursor: pointer;
    background-color: #ffffff;
}
.button:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-top-color: #9a9a9a;
    right: 20px;
    top: 15px;
}
.Appsss {
    width: 100%;
    float: left;
    z-index: 999;
    background-color: #f7f7f7;
    box-shadow: 0px 0px 17px #e2d5d5;
}
.sqmnsss li{
   display: inline-block;
   float: right;
}
.header-middle .col-lg-9.col-sm-9{
    z-index: 7;
    padding: 0;
}
.premiumsss {
    padding: 0px 0 60px 0;
    width: 100%;
    float: left;
    position: relative;   
    overflow: hidden;
    /* background-image: url(./Img/sss.jpg); */
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    z-index: -7;
}
.topsss{
    margin: 27px 0 0 0;
    display: block;
    width: 100%;
    float: left;
}
.book-wrappertitlss {
    font-size: 17px;
    margin: 10px 0 0 0;
    text-align: center;
    height: 57px;
    overflow: hidden;
    clear: both;
    display: block;
    color: #353535;
    font-weight: 500;
    max-width: 96%;
    line-height: 27px;
}
.app:hover .book-wrappertitlss{
    color: #4d8c05;
}
.app:hover .hover-btns {
    opacity: 1;
    top: 33%;
}
.app .hover-btns {
    position: absolute;
    opacity: 0;
    top: 55%;
    width: fit-content;
    text-align: center;
    margin: 0 auto;
    padding: 7px 13px 7px 13px;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 3;
    border-radius: 3px;
    box-shadow: 0 3px 5px 0 rgba(0,0,0,.15);
    transition: all .3s ease-in-out;
    display: inline-block;
    color: #f35202;
    box-shadow: 2px 2px 7px #fd6500;
}
.app .hover-btns:hover{
    color: #98c06a;
    box-shadow: 2px 2px 7px #98c06a;
}
.is-active{
    color: #f35202!important;
}
.premiumsssa1 {   
    color: #ffffff;
    text-align: center;
    margin-bottom: 0px;
    font-size: 14px;
    letter-spacing: 3px;
    text-shadow: 3px 3px 7px #7da6dc;
}
.zzzss{   
    z-index: 7;
    float: right;
    text-align: right;
    color: #ffffff;
}
.zzzss a {
    color: #ffffff;
    margin: 0 10px 0 10px;
    font-size: 15px;
}
.searchiconsss{
    vertical-align: middle;
    height: 14px!important;
    margin-left: 17px;
    color: #ffffff;
    cursor: pointer;
    margin-right: 7px;
}
.searchiconsss:hover{
    color: #f35202;
}
.premiumsss h6 {
    font-size: 17px;
    margin-top: 37px;
    margin-bottom: 0;
    color: #fd6500;
}
.premiumsssa2 {
    color: #000000;
    text-align: right;
    margin-bottom: 37px;
    font-size: 17px;
    /* animation: myanimation 200s infinite; */
    background-color: #fbe4c5;
    padding: 7px 27px 10px 27px;
    border-top-left-radius: 17px;
    border-bottom-left-radius: 17px;
    display: inline-block;
    float: right;
    background: linear-gradient(180deg,#e0dad2 0,#efedeb00 0,#d8d6d491 99%);
    box-shadow: 3px 3px 3px #f1efef;
    font-weight: 400;
}
.premiumsssa3 {
    color: #000000;
    margin-bottom: 37px;
    font-size: 17px;
    /* animation: myanimation 200s infinite; */
    text-align: left;
    background-color: #fbe4c5;
    padding: 7px 27px 10px 27px;
    border-top-right-radius: 17px;
    border-bottom-right-radius: 17px;
    display: inline-block;
    background: linear-gradient(180deg,#e0dad2 0,#efedeb00 0,#d8d6d491 99%);
    box-shadow: 3px 3px 3px #f1efef;
    font-weight: 400;
}
@keyframes myanimation {
    0% {color: #f35202;}
    25% {color: #f35202;}
    50% {color: #000000;}
    75% {color: #f35202;}
    100% {color: #000000;}
  }
@keyframes myanimation2 {
    0% {color: #000000;}
    25% {color: #000000;}
    50% {color: #f35202;}
    75% {color: #f35202;}
    100% {color: #000000;}
  }
.logo{
    position: relative;
}
@media(min-width:992px){ 
.top-seccc .col-md-2 {
    flex: 0 0 19.666667%;
    max-width: 19.666667%;
}
}
.top-seccc .book {
    max-width: 237px;
    max-height: 300px;
    transform: rotateX(0deg) rotateY(0deg);
}
.top-seccc .book-wrapper img {
    max-width: 237px;
    max-height: 300px;
}
.nav__list li:first-child .nav__label {
    color: #fd6500;
}
.book__front:before {
    content: "";
    position: absolute;
    height: 100%;
    top: 0;
    left: 0px;
    width: 100%;
    background: url(./Img/book-after.png) repeat-y left;
    background-size: 100% auto;
    opacity: 1;
}
.new {
    background: #98c06a none repeat scroll 0 0;
    color: #fff;
    font-size: 11px;
    left: 27px;
    padding: 3px 10px 3px 9px;
    position: absolute;
    text-transform: uppercase;
    top: 7px;
    display: none;
}
.new:before {
    bottom: -9px;
    color: #40bea7;
    content: "";
    font-family: fontawesome;
    font-size: 12px;
    left: 50%;
    margin-left: -4px;
    position: absolute;
}
.packagesec {
    position: relative;
    overflow: hidden;
    padding: 17px 0 27px 0;
}
.profilepageleftsss .svg-inline--fa {
    height: 17px;
}
#clouds {
    position: absolute;
    float: left;
    width: 100%;
    left: 0;
    bottom: -77px;
}
.cloud {
    width: auto;
    height: 107px;
    background-image: url(./Img/clouds.png);
    background-size: cover;
    background-repeat: repeat;
    animation: moveclouds 37s linear infinite;
    -webkit-animation: moveclouds 37s linear infinite;
    -moz-animation: moveclouds 37s linear infinite;
    -o-animation: moveclouds 37s linear infinite;
    opacity: 0.7;
}
.cloud:after {
    position: absolute;
    content: "";
    background-image: url(./Img/clouds.png);
    width: 100%;
    height: 100%;
    left: 0;
    z-index: -7;
    opacity: 0.3;
}
@keyframes moveclouds {
	0% {margin-left: 700px;}
	100% {margin-left: -700px;}
}
@-webkit-keyframes moveclouds {
	0% {margin-left: 700px;}
	100% {margin-left: -700px;}
}
@-moz-keyframes moveclouds {
	0% {margin-left: 700px;}
	100% {margin-left: -700px;}
}
@-o-keyframes moveclouds {
	0% {margin-left: 700px;}
	100% {margin-left: -700px;}
}
.reccc {
    background: linear-gradient(90deg, #ffffff 83%, #f1edec00 50%) repeat scroll 0 0;
    margin-bottom: 27px;
}
.audiosssec{
    background-image: url(./Img/slidess2.png);
    background-position: right;
    background-repeat: no-repeat !important;
    background-size: contain;
    margin: 47px 0 0 0;
}
.audiosssbbb h6 {
    color: #397101;
    text-align: center;
    margin-bottom: 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 17px;
}
.audiosssbbb h4 {
    margin: 0 auto 13px;
    text-align: center;
    font-size: 17px;
    max-height: 60px;
    overflow: hidden;
    width: 87%;
    float: none;
    line-height: 27px;
    text-align: center;
    color: #000000;
}
.cccgggsss2 {
    height: 40px;
    overflow: hidden;
    color: black;
    text-align: center;
    margin: 0 auto;
    display: block;
    width: 100%;
    padding-top: 13px;
}
.price2 {
    color: #62ab00;
}
.login-1nnn{
    padding: 0px 0px 60px 0px;
}
.login-1nnn .c-form{
    padding: 27px;
    width: 100%;
    float: left;
    box-shadow: 2px 0px 7px #e8e4e4;
}
.section-title--bordered2 {
    position: relative;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 17px;
}
.aaabbbsss{
    background-image: url(./Img/pattern.png);
    background-position: 50%;
    background-size: contain;
    background-repeat: repeat;
    background-attachment: fixed;
    padding: 60px 0 60px 0;
    float: left;
    width: 100%;
}
.wqssingaaaass{
        box-shadow: 0 0 30px 0 rgba(67,67,67,.15);
        background-color: white;
        padding: 57px 37px;
        background-image: url(./Img/bg-1.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        background-attachment: fixed;
        position: relative;
        z-index: 0;
}
.wqssingaaaass .bg-overlay {
    background: rgba(255, 255, 255, 0.78);
    left: 0;
    z-index: -1;
}
.aaabbbsss h2 {
    position: relative;
    text-transform: uppercase;
    display: block;
    vertical-align: top;
    line-height: 30px;
    color: #fd6500;
    font-weight: 600;
    font-size: 27px;
    margin-bottom: 17px;
    text-align: center;
    letter-spacing: 1px;
}
.aaabbbsss p {
    padding: 7px 37px 7px 37px;
    font-size: 18px;
    line-height: 47px;
    color: #504e4e;
    width: 97%;
    margin: 0 auto;
}
.aaabbbsss a{
    color: #fd6500;
}
.pagsss .book-wrapper img {
    height: 247px;
    max-width: 197px;
}
.basccsss {
    padding: 0;
    height: 327px;
    overflow: hidden;
    border-bottom: 1px solid #e6e6fa;
    margin-bottom: 47px;
}
.pagsss h2 {
    text-transform: capitalize;
    display: block;
    vertical-align: top;
    line-height: 30px;
    color: #000000;
    font-weight: 500;
    font-size: 27px;
    margin-bottom: 17px;
    letter-spacing: 1px;
}
.section-title4 {
    width: 100%;
    float: left;
    position: relative;
    display: block;
    position: relative;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 47px;
}
.breadcrumb-section{
    float: right;
    width: 100%;
}
.breadcrumb {
    background-color: #e9ecef00;
    float: right;
    margin-bottom: 0px;
}
.pagsssqqq{
    padding: 27px 0 0 0;
}
.pagsssdesccc h3 {
    color: #f04501;
    font-size: 20px;
    font-family: Roboto,sans-serif;
    line-height: 27px;
}
.pagsssdesccc h6{
    color: #4f8407;
    font-weight: 400;
    font-family: Roboto,sans-serif;
}
.breadcrumb a {
    color: #b98907;
}
.standardsss{
    background-image: url(./Img/bg-1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    padding: 60px 0 60px 0;
    float: left;
    width: 100%;
    margin: 47px 0 60px 0;
    position: relative;
    display: flex;
}
.bg-overlay {
    background: rgb(255,255,255,0.7);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
}
.secreccc3 .book {
    transform: rotateX(0deg) rotateY(-15deg);
}
.stansss h6 {
    margin: 0 auto 7px 10px;
    text-align: left;
    color: #fd6500;
    font-weight: 300;
    line-height: 27px;
    font-size: 17px;
}
.stansssmmm {
    height: 483px;
    overflow: hidden;
    background-color: #ffffff;
    padding: 10px 10px 17px 10px;
    margin: 17px 10px 17px 10px;
}
.stansssmmm img {
    height: 287px;
    width: auto;
    overflow: hidden;
    border-radius: 0.5vmin;
    box-shadow: -1px 1px 10px #807f7f;
    margin: 0 auto 17px;
    display: block;
}
@media(min-width:992px){ 
.secreccc3qqq .col-md-2 {
    flex: 0 0 19.666667%;
    max-width: 19.666667%;
}
}
.secreccc3qqq .book-wrapper img {
    max-width: 187px;
    height: 237px;
}
.secreccc3qqq .book {
    transform: rotateX(0deg) rotateY(-15deg);
}
.secreccc3qqq .app {
    padding: 13px 0 23px 0;
}
.stansssmmm h4 {
    margin: 7px 0 10px 0;
    text-align: center;
    font-size: 17px;
    height: 53px;
    overflow: hidden;
    color: #0a0a0a;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    line-height: 23px;
    text-transform: capitalize;
}
.stansssmmm h6 {
    color: #407107;
    margin: 7px auto 7px;
    text-align: center;
    border-top: 1px dashed lavender;
    border-bottom: 1px dashed lavender;
    padding: 5px 0 7px 0;
    font-size: 16px;
    font-weight: 400;
}
.stansssmmm p {
    text-align: center;
    margin: 0px 0 0px 0;
    font-size: 17px;
    color: #f35202;
}
.secreccc3 .book-wrapper img {
    height: 207px;
    max-width: 167px;
}
.secreccc3 .app {
    padding: 13px 0 13px 0;
}
.secreccc3 .book-shadow {
    width: 127px;
    height: calc(17px + var(--thickness));
    filter: blur(27px);
}
.stansss h4 {
    margin: 7px 0px 0px 10px;
    text-align: left;
    font-size: 18px;
    max-height: 53px;
    line-height: 26px;
    overflow: hidden;
    color: #000000;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
}
.stansss h2{
    line-height: 30px;
    font-size: 20px;
}
.standesccc {
    max-height: 173px;
    overflow: hidden;
    margin-left: 10px;
}
.standesccc span {
    font-size: 16px!important;
    font-family: 'Roboto Slab', sans-serif!important;
    text-align: left;
    color: #3a3636!important;
    float: left;
}
.standesccc p{
    color: #3a3636!important;
}
.stansss p{
    text-align: center;
    margin: 0px 0 0px 0;
    font-size: 20px;
    color: #f35202;
}
.pubsss {
    background-color: #ffffff;
    float: left;
    padding: 17px;
    box-shadow: 0px 2px 7px #e8e4e4;
}
.single-btn2 h3{
    font-size: 18px;
    line-height: 27px;
    font-weight: 500;
}
/* -------------------------------------------------------------------------------------------- */
/* book cover stylesss */

.media-container:hover .book {
    -webkit-transform: rotateX(5deg) rotateY(-50deg);
    transform: rotateX(5deg) rotateY(-50deg);
}
.app {
    --thickness: 20px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-perspective: 1500px;
    perspective: 1500px;
}
.app[data-current-media="book"] .book-wrapper {
    display: block;
}
.book {
    position: relative;
    z-index: 1;
    max-width: 237px;
    max-height: 367px;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transition: -webkit-transform 1s ease;
    transition: -webkit-transform 1s ease;
    transition: transform 1s ease;
    transition: transform 1s ease, -webkit-transform 1s ease;
    -webkit-transform: rotateX(0deg) rotateY(-23deg);
    transform: rotateX(0deg) rotateY(-23deg);
}
.book__front {
    border-radius: 5px;
    overflow: hidden;
    -webkit-transform: translateZ(calc(var(--thickness)));
    transform: translateZ(calc(var(--thickness)));
}
.book__paper {
    height: 98%;
    width: calc(var(--thickness) * 2);
    background: white;
    position: absolute;
    top: 1%;
    right: 0;
    background: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.3)), to(rgba(255, 255, 255, 0))), repeating-linear-gradient(90deg, white, #e2e2e2 1px, white 3px, #9a9a9a 1px);
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.3), rgba(255, 255, 255, 0)), repeating-linear-gradient(90deg, white, #e2e2e2 1px, white 3px, #9a9a9a 1px);
    -webkit-transform: rotateY(90deg) translateX(calc(var(--thickness) / 7)) translateZ(calc(var(--thickness) / 1.2));
    transform: rotateY(90deg) translateX(calc(var(--thickness) / 7)) translateZ(calc(var(--thickness) / 1.2));
}
.book__back {
    width: 100%;
    height: 99.4%;
    position: absolute;
    top: 0;
    background: #356143;
    border-radius: 5px;
    overflow: hidden;
    -webkit-transform: translateZ(calc(-1 * var(--thickness)));
    transform: translateZ(calc(-1 * var(--thickness)));
}
.book-shadow {
    position: absolute;
    width: 167px;
    height: calc(33px + var(--thickness));
    background: radial-gradient(70% 85%, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%);
    -webkit-transform: rotateX(90deg) rotateZ(30deg);
    transform: rotateX(90deg) rotateZ(30deg);
    -webkit-transition: -webkit-transform 1s ease;
    transition: -webkit-transform 1s ease;
    transition: transform 1s ease;
    transition: transform 1s ease, -webkit-transform 1s ease;
    -webkit-filter: blur(32px);
    filter: blur(32px);
}
.book-wrapper img{
    max-width: 237px;
    width: auto;
    height: 296px;
    overflow: hidden;
    object-fit: cover;
    object-position: center top;
    margin: 0 auto;
    display: block;
}
.book-wrappertitlss2 {
    font-size: 18px;
    color: #fd6500;
    margin: 10px 0 0 0;
}


/* ----------------------------------------------------------------------------------------------------- */
.qqqcentral-meta {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #ede9e9;
    border-radius: 5px;
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
    padding: 37px;
    position: relative;
}

.qqqcentral-meta .form-group {
    padding: 0;
}
ul#buttons {
    padding: 0;
    margin: 0;
    position: relative;
    transform: translate(0%,0%);
    list-style: none;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 10px;
}

ul#buttons:before {
	content: "";
	font-size: 2em;
	font-family: Arial;
	font-weight: 300;
	color: #4d4d4d;
	position: absolute;
	width: 100%;
	text-align: center;
	margin-top: -60px;
}
ul#buttons li {
    width: 53px;
    height: 53px;
    line-height: 60px;
    text-align: center;
    box-sizing: border-box;
    background: transparent;
    border-radius: 7px;
    position: relative;
    overflow: hidden;
    transition: .5s;
    box-shadow: 0px 8px 16px -6px, 0px 0px 16px -6px;
}

ul#buttons li a {
	display: block;
	widows: 100%;
	height: 100%;
	font-size: 1.25em;
	background: transparent;
	transition: .5s;
	animation: icon-out .5s forwards;
	animation-timing-function: cubic-bezier(0.5, -0.6, 1, 1);
}

ul#buttons li:before {
	content: "";
	width: 90px;
	height: 90px;
	display: block;
	position: absolute;
	transform: rotate(-45deg) translate(-110%, -23px);
	z-index: -2;
	animation: back-out .5s forwards;
	animation-timing-function: cubic-bezier(0.5, -0.6, 1, 1);
}

ul#buttons li:hover a {
	animation: icon-in .5s forwards;
	animation-timing-function: cubic-bezier(0, 0, 0.4, 1.6);
}

ul#buttons li:hover:before { 
	animation: back-in .5s forwards;
	animation-timing-function: cubic-bezier(0, 0, 0.4, 1.6);
}

@keyframes back-in {
    0% { transform: rotate(-45deg) translate(-110%, -23px); }
	80% { transform: rotate(-45deg) translate(5%, -23px); }
	100% { transform: rotate(-45deg) translate(0%, -23px); }
}

@keyframes back-out {
    0% { transform: rotate(-45deg) translate(0%, -23px); }
	20% { transform: rotate(-45deg) translate(5%, -23px); }
	100% { transform: rotate(-45deg) translate(-110%, -23px); }
}

@keyframes icon-in {
    0% { font-size: 1.25em; }
	80% { color: #fff; font-size: 1.5em; }
	100% { color: #fff; font-size: 1.35em; }
}

@keyframes icon-out {
	0% { color: #fff; font-size: 1.35em; }
	20% { color: #fff; font-size: 1.5em; }
	100% { font-size: 1.25em; }
}
.fb a, .fb:before, .fb { background: #3b5999; color: #3b5999; }
.tw a, .tw:before, .tw { background: #55acee; color: #55acee; }
.in a, .in:before, .in { background: #e4405f; color: #e4405f; }
.gh a, .gh:before, .gh { background: #333333; color: #333333; }
.lin a, .lin:before, .lin {
    background: #0084b1;
    color: #0084b1;
}
/* --------------------------------------------------------------------------------------------- */
.search-field {
    border-radius: 0 !important;
    height: calc(1.3em + .13rem + 15px);
    border: 1px solid #eee;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    box-shadow: none!important;
    max-width: 300px;
    padding: 3px 7px 3px 7px;
    font-size: 15px;
    display: inline-block;
    float: right;
  }
  .input-groupsss{
    float: right;
    display: block;
    width: 100%;
    position: absolute;
    top: -18px;
  }
  .search-btn {
    border-radius: 0 !important;
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
    width: 6.0em;
    background-color: #f35202;
    color: white;
    padding: 5px 0 5px 0;
    float: right;
}
.svg-inline--fa {
    height: 0.7em;
}



























































































































   
@media (min-width: 1300px){
    .container{
       max-width: 1280px;
    }


}
@media (min-width: 992px){
       ul.main-menu {
           display: flex;
           position: relative;
        list-style: none;
        margin: 0;
        padding: 0;
        float: right;
       }
       ul.main-menu>li.menu-item:first-child {
           padding-left: 0;
       }
       ul.main-menu>li.menu-item>a:last-child {
           margin-right: 0;
       }
       ul.main-menu>li.menu-item>a {  
            margin-left: 15px;
            margin-right: 15px;
       }
       ul.main-menu>li .sub-menu {
           padding: 30px;
       }
       ul.main-menu>li .sub-menu {
            width: 227px;
            position: absolute;
            top: 100%;
            left: 0;
            background: #f7f7f7;
            border: 2px solid #eee;
            margin-top: 7px;
            opacity: 0;
            pointer-events: none;
          }
          ul.main-menu>li .sub-menu li {
              margin-bottom: 15px;
              color: #333;
              text-transform: capitalize;
              font-weight: 400;
              font-size: 14px;
              margin-bottom: 10px;
          }
          ul.main-menu .has-children:hover>.sub-menu {
              pointer-events: visible;
              opacity: 1;
          }
          ul.main-menu .has-children:hover {
              z-index: 200;
          }
          
ul.main-menu .has-children {
       position: relative;
   }


    }  

    @media (max-width: 991px){ 
    
    .menuq1{
           display:none;
    }
    .menuq2{
           display:block!important;
    }

}
.gray-bg {
    float: left;
    width: 100%;
    background: #edf2f6;
    /* background: linear-gradient(0deg, #edf2f6 27%, #ffffff00 103%) repeat scroll 0 0; */
}
.profileimgsss {
    width: 137px;
    max-width:137px;
    max-height:137px;
    border-radius: 100%;
    overflow: hidden;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    border-radius: 100%;
    height: 137px;
}
.myaccounts{  
    padding: 0px 0px 60px 0px;
}
.profilepageleftsss{
    background-color: white;
}
.profilepageleftsss2{
    padding: 7px 7px 7px 17px;
    position: relative;
}
.profilepageleftsss2 .btn-primary {
    border-color: #f3641b00;
    float: right;
    background: linear-gradient(180deg,#f35c1500 0,#f9964100 0,#f35c1500 99%);   
    color: #545151;
    margin-top: 37px;
}
.profilepageleftsss2 .btn-primary:hover {
    border-color: #f3641b!important;
    float: right;
    background: linear-gradient(180deg,#f35c15 0,#f99641 0,#f35c15 99%);
    color:white;
}
.profilepageleftsss h2{
    border-bottom: 1px solid #e6ecf5;
    color: #515365;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    padding: 15px 20px;
    position: relative;
    text-transform: capitalize;
    width: 100%;
}
.profilepageleftsss h2::before {
    content: "";
    height: 15px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 3px;
    background: #fa6342;
}
.central-meta {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #ede9e9;
    border-radius: 5px;
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
    padding: 37px 17px 27px 17px;
    position: relative;
}
.nav-tabssss {
    border: medium none;
    border-bottom: none;
    border-right: 1px solid #ddd;
    display: flex;
    flex-flow: column nowrap;
    padding: 0;
    height: 77vh;
    margin-top: 37px;
}
.nav-tabssss li {
    color: #3b3b40;
    white-space: nowrap;
    font-size: 17px;
    font-weight: 500;
    list-style: none;
    margin-bottom: 27px;
    cursor: pointer;
    font-family: Roboto,sans-serif;
}
.nav-tabssss li:hover {
    color: #fd6500;
}
.nav-tabssss svg {
    margin-right: 7px;
    height: 16px;
}
.myaccounts .active{
    color: #fd6500!important;
}
.set-title h5{
    border-bottom: 1px dashed #cecece;
    padding-bottom: 10px;
    margin-bottom: 27px;
    color: #fd6500;
}
.acqcss2 li a {
    border: 1px solid #cecece;
    border-bottom: none;
    color: #333;
    font-weight: 500;
    font-size: 17px;
    display: block;
    padding: 10px 25px 10px;
    text-transform: capitalize;
    background: linear-gradient(to bottom,#78a1d8,#34465e);
    color: #fff;
    font-family: Roboto,sans-serif;
}
.acqcss2 li {
    margin-bottom: 37px;
    display: inline-block;
    margin-right: 17px;
}
.myaccounts .pagsssqqq {
    width: 100%;
    padding: 27px 0 17px 0;
}
.acqcss2 {
    margin: 10px 0 17px 0;
    float: left;
    width: 100%;
    clear: both;
    display: block;
    padding: 0px;
}
.acqcss2 li a:hover {
    background: linear-gradient(to bottom,#f7b733,#fc4a1a);
}
.central-meta2 {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #ede9e9;
    border-radius: 5px;
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
    padding: 17px 0 0 0;
    position: relative;
}
.central-meta2 .form-control {
    height: auto;
    font-size: 17px;
    line-height: 27px;
    color: #fd6500;
    background: #edf2f6 none repeat scroll 0 0;
    border: 1px solid #e4e4e4;
}
.central-meta2 .btn-primary{
    border-color: #f3641b!important;
    float: right;
    background: linear-gradient(180deg,#f35c15 0,#f99641 0,#f35c15 99%);
    color:white;
}
.c-form > div input, .c-form > div textarea, .c-form > input, .c-form > textarea {
    background: #edf2f6 none repeat scroll 0 0;
    border-radius: 4px;
    font-size: 17px;
    padding: 25px 15px;
    width: 100%;
    color: #535165;
    border: 1px solid #e4e4e4;
    margin-bottom: 27px;
}
.c-form > div label {
    color: #515365;
    display: inline-block;
    font-size: 17px;
    margin-bottom: 5px;
    width: 100%;
    font-weight: 500;
    text-transform: capitalize;
}
.addbooksss{  
    padding: 60px 0 60px 0;
}
.central-meta3 {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #ede9e9;
    border-radius: 5px;
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
    padding: 37px;
    position: relative;
}
.public-DraftStyleDefault-ltr {
    margin-left: 17px;
}
.central-meta3 h3{   
    font-size: 23px;
    margin-bottom: 17px;
    color: #000000;
    text-transform: capitalize;
}
.addbooksss .form-control {
    display: block;
    font-weight: 400;
    line-height: 1.5;
    background-color: #fff;
    background-clip: padding-box;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    background: #edf2f6 none repeat scroll 0 0;
    border-radius: 4px;
    font-size: 16px;
    padding: 14px 15px;
    width: 100%;
    color: #535165;
    border: 1px solid #e4e4e4;
    height: auto;
    margin-bottom: 27px;
}
.addbooksss label {
    color: #515365;
    display: inline-block;
    font-size: 16px;
    margin-bottom: 7px;
    width: 100%;
    font-weight: 500;
    text-transform: capitalize;
}
.removeqqq{
    margin: 0 0 0 27px;
    background: linear-gradient(180deg,#f35c15 0,#f99641 0,#f35c15 99%);
    color: white;
    outline: none;
    padding: 7px 17px 7px 17px;
    font-size: 16px;
}
 .public-DraftEditor-content {
    min-height: 300px;
}
.rdw-editor-main {
    background-color: #edf2f6;
    border: 1px solid #edf2f6;
    margin-bottom: 17px;
}
.basccsss .app{
    margin-top: 17px;
}
.basccsss2 .single-btnmmm {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #34465e;
    color: #fff;
}
.basccsss2 .single-btnmmm:hover {
    background-color: #62ab00;
}
.acqcss2 .menu-item a:hover {
    color: #ffffff;
}
.search .book-wrapper img {
    max-width: 177px;
    height: 177px;
}
.search .stansss h4 {
    margin: 7px 0px 17px 10px;
    font-size: 20px;
    max-height: 100%;
    line-height: 37px;
}
.search .stansss {
    height: 267px;
}
.search .stansss p {
    margin: 30px 0 0px 0;
    font-size: 20px;
}
.search .stansss h6 {
    font-size: 20px;
}
.search .description {
    float: left;
    margin-top: 0;
    width: 100%;
    text-align: left;
    border-bottom: 1px solid #e6e6fa;
    margin-bottom: 27px;
    padding-bottom: 17px;
    height: 247px;
    overflow: hidden;
}
.search .active {
    color: #fd6500!important;
}
.searchbar {
    margin: 17px auto 47px;
    position: relative;
    display: table;
}
.search .searchbar input {
    font-size: 23px;
    width: 700px;
    height: 5rem;
    border: none;
    outline: none;
    border-radius: 10rem;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
    padding: 2rem;
    transition: all .2s;
    transition-delay: .1s;
    font-family: Roboto,sans-serif;
}
.search .searchbar .btnmmm {
    height: 2.7rem;
    position: absolute;
    top: 7px;
    right: 17px;
    transition: all .2s;
    transition-delay: .1s;
    border: 0;
    padding: 17px;
    background-color: #f67d4100;
    color: #f67d41;
    font-size: 23px;
    outline: none;
}
.search .searchbar .btnmmm img {
    height: 20px;
    top: 1.5rem;
    right: 1rem;
    transition: all .2s;
    transition-delay: .1s;
}
.search .searchbar .btnmmm:hover {
    color: #34465e;
}
.search {
    float: left;
    width: 100%;
    background: #ffffff;
    min-height: 100vh;
}
.search .nav-tabssss li {
    color: #000000;
    white-space: nowrap;
    border-bottom: 1px solid lavender;
    padding: 10px 10px 10px 10px;
    width: 76%;
    background-color: #f7f7f7;
    display: block;
}
.coupengeneratesss{
    background-color: #edf2f6;
    padding: 17px;
    text-align: center;
    color: #f04401;
    font-weight: 600;
    letter-spacing: 1px;
    font-size: 20px;
    margin-bottom: 17px;
}
.coupengeneratesss2{
    background-color: #34465e;
    background: linear-gradient(to bottom,#78a1d8,#34465e);
    color: white;
    padding: 10px 27px 10px 27px;
    font-size: 17px;
    border: 0;
    margin: 0 auto 27px;
    display: block;
}
.coupengeneratesss3 {
    background-color: #edf2f6;
    margin: 17px auto 27px;
    display: block;
    text-align: center;
}
.coupengeneratesss3 input{
    font-size: 20px!important;
    color: black!important;
    background-color: white!important;
    padding: 20px!important;
    width: 233px!important;
}
.coupengeneratesss4 input[type=checkbox] {
    height: 20px;
    width: 20px;
    background-color: #fa6342;
    color: #fa6342;
    border: 0;
}
.form-buttonccc {
    background: linear-gradient(to bottom,#f7b733,#fc4a1a)!important;
}
.form-buttonccc2 {
    background: linear-gradient(to bottom,#78a1d8,#34465e)!important;
}
.coupengeneratesss5{
    background-color: #edf2f6;
    width: 100%;
    float: left;
    padding: 17px 10px 17px 10px;
    margin-bottom: 27px;
}
.coupengeneratesss5 h6{
    color: #f04501;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 17px;
}
.coupengeneratesss5 h3{
    background-color: white;
    padding: 10px 10px 10px 10px;
    margin-bottom: 17px;
    text-align: center;
    color: #5b7aa3;
}
@media(max-width:767px){
    .coupengeneratesss3 input {  width: 147px!important;}
    .search .searchbar input {font-size: 18px; width: 100%; }
    .search .searchbar .btnmmm img {   height: 27px; right: 0rem; background-color: white; }
    .books2::after{ display: none; }
    .books2 { float: none;}
    .books2 .hover-btns {max-width: 137px; }
    .headsesssbutton {  display: none; }
     .premiumsssa1 {   display: none; }
    .searchiconsss {   display: none; }
    .books3list { display: block;float: none;}
    .books3list img { float: none; margin: 0 auto; }
    .books3list::after{ display: none; }
    .latestbooks { margin: 37px 0 27px 0;}
    .booksrec::after{ display: none; }
    .booksrec { float: none;margin-left: 0; margin-top: 0px; }
    .header-middle img {  margin: 0 auto;display: block;}
    .premiumsssa2 {  font-size: 16px; padding: 7px 10px 10px 10px;  float: none;text-align: center;line-height: 23px; margin-bottom: 10px; }  
    .book-wrappertitlss { margin: 10px 0 20px 0; }
    .nav__list {  display: none;  }
    .latestbooks2 .col-md-3{    display: inline-block; width: 47%;}
    .latestbooks2 .col-md-9{  display: inline-block; width: 50%; }
    .books3list img {   max-width: 87px;  margin-right: 17px; }
    .latestbooks2 { height: 120px;  }
    .secreccc .section-title h2 {font-size: 37px; line-height: 53px;}
    .reccc { background: linear-gradient(90deg, #ffffff 83%, #ffffff 50%) repeat scroll 0 0;  margin-bottom: 17px;  padding-bottom: 27px; }
    .booksrec2 img { margin: 17px auto;  }
    .pubsss { margin-bottom: 47px;  }
    .section-title2 h2 {line-height: 37px;  font-size: 27px; }
    .secreccc3qqq .app { padding: 0px 0 0px 0; margin: 0; }
    .stansss {  height: 477px; }
    .wqssingaaaass {   padding: 17px 10px; }
    .aaabbbsss p {
        padding: 7px 7px 7px 7px;
        line-height: 37px;
        width: 100%;
    }
    .footer img{
        max-width: 187px;
        height: auto;
        margin: 0 auto 27px;
        display: block;
    }
    .user-log {  display: none;  }
    .logsss2 { display: none; }
    .logsss3 { padding: 0 15px 0 15px!important;}
    .logsss {  display: none;}
    .res5c {
        display: table!important;
        float: none;
        background-color: #34465e;
        color: #fff;
        padding: 7px;
        border-radius: 5px;
        margin: 10px auto;
    }
    .res5c .svg-inline--fa {
        width: 30px;
        height: 30px;
        vertical-align: middle;
        padding: 3px;
    }
    .menuq2 { background-color: white;  padding: 27px;  }
    .menu-item {  margin-bottom: 17px;  }
    .sub-menu{ margin-top: 17px; background-color: #f7f7f7; padding: 17px; }
    .central-meta { padding: 17px 17px 17px 17px; }
    .nav-tabssss { height: auto; margin-top: 10px;}
    .acqcss2 li {  margin-bottom: 17px; }
    .central-meta3 {  padding: 10px 3px 0 7px;}
    .listing-duration3 h1 {  font-size: 17px;line-height: 30px; }
    .addbooksss { padding: 27px 0 27px 0; }
    .section-title4 { margin-bottom: 37px;margin-top: 27px; }
    .basccsss { padding: 0 0 27px 0; height: auto;margin-bottom: 17px; }
    .basccsss .app { margin-bottom: 27px;}
    .pagsssdesccc h3 {text-align: center;  }
    .pagsssdesccc h6 { margin-top: 17px; text-align: center; margin-bottom: 17px;}
    .breadcrumb { padding: 0;}
    .section-title--bordered { margin-bottom: 17px; margin-top: 27px;}
    .videossspagsss h3 {font-size: 18px;line-height: 30px; text-align: center; }
    .videosss2 { width: 100%; height: auto;  }
    .videossspagsss { height: auto; }
    .quicskkssspubsss { height: auto; }
    .basccsss3 {height: auto;padding-bottom: 17px;}
    .pagsssdescccmmm h3 {margin-top: 17px;}
    .bestseller-content2 img { width: 100%; max-width: 180px;float: none;margin: 27px auto!important; display: block; }
    .basccsss2 {height: auto; }
    .bestseller-content2 h2 { font-size: 27px; text-align: center;width: 100%; margin-top: 0; margin-bottom: 0;  }
    .avatar-previewqqq { margin-top: 37px; }
    .srfefdsgbfedv {padding: 7px; margin-top: 37px; }
    .zzz2nnn h3 {line-height: 27px; margin-top: 17px; text-align: center; }
    .central-metazzzmmm { padding: 7px; word-break: break-all;}
    .redmoesss { margin-top: 7px; max-height: 277px;padding: 10px 0 0 0;}
    .zzz2 { margin-top: 27px; }
    .central-metazzz { padding: 10px;word-break: break-all; }
    .waestae2 { height: auto;  margin-bottom: 17px; }
    a{ word-break: break-all;}
    .aunasss { display: none;}
    .premiumsssa3 { display: none;}
    .secreccc3qqq { padding: 17px 0 60px 0; }
    .yyyview h3 {clear: both;}




}

@media (min-width: 768px) and (max-width:991px){ 
    .nav-tabssss li { white-space: unset!important; }
    .hhselss { height: 167px;}
    .latestbooks p { line-height: 27px; margin-bottom: 0;}
    .books3list img {max-height: 52px; }
    .col-md-2 {flex: 0 0 19.666667%; max-width: 19.666667%; }
    .top-seccc .book{max-width: 207px;max-height: 207px;}
    .top-seccc .book-wrapper img { max-width: 207px; max-height: 207px;}
    .res5c {display: table!important; float: right;background-color: #34465e;color: #fff; padding: 10px; border-radius: 5px; margin: 10px auto; }
    .res5c .svg-inline--fa { width: 30px;  height: 30px; vertical-align: middle; padding: 3px; }
    .menuq2 { background-color: white;  padding: 27px; position: absolute;right: 0; top: 67px;width: 100%;  }
    .menu-item {  margin-bottom: 17px;  }
    .sub-menu{ margin-top: 17px; background-color: #f7f7f7; padding: 17px; }
    .premiumsssa1{display: none;}
    .zzzss {  padding: 0; }
    .books2 img {  height: 137px!important; }
    .books2 {  height: 137px!important; }
    .yyy img {width: 97px; height: 97px; }
    ul#buttons li {width: 50px; height: 50px; margin-right: 7px; }
    .latestbooks2 {height: auto; padding-bottom: 17px; }
    .booksrec { height: 167px; margin-left: 0px; }
    .secreccc .booksrec img { height: 167px; }
    .yyybuttons3 {  padding: 7px 10px 7px 10px;}
    .pubsss .listqqq{display: none; }
    .booksrec2 img { height: 67px; }
    .stansssmmm img { height: auto; }
    .stansssmmm { height: auto; }
    .latestbooks2qqq .book-wrapper img {max-width: 122px; height: 127px;}
    .audiosssbbb {height: auto; }
    .secreccc3 .book-wrapper img { height: 137px; max-width: 97px; }
    .aaabbbsss p {padding: 0; width: 100%; }
    .stansss2 { height: 327px; }
    .addbooksss .col-md-10 { flex: 0 0 100%; max-width: 100%;}
    .pagsss .book-wrapper img { height: 137px; max-width: 197px;}
    .yyyview img {width: 97px;  height: 97px; }
    .book-wrapper img { height: 237px; }
    .videosss2 {height: auto; }
    .videossspagsss h3 {color: #383636; font-size: 18px;  line-height: 30px; text-align: center;}
    a{ word-break: break-all;}
    .waestae2 { height: auto;}



}

@media (min-width: 992px) and (max-width:1199px){ 
    .top-seccc .book-wrapper img {max-width: 237px; max-height: 267px; }
    .premiumsssa2 { font-size: 16px; padding: 7px 10px 10px 10px; }
    .premiumsssa3 { font-size: 16px; padding: 7px 10px 10px 10px; }
    ul.main-menu>li.menu-item>a {font-size: 15px; letter-spacing: 0;}
    .zzzss {padding: 0;}
    .zzzss a { margin: 0!important;font-size: 14px; }
    .booksrec {height: 187px;}
    .secreccc .booksrec img { height: 187px;}
    .booksrec2 img {height: 87px; }
    .secreccc3 .book-wrapper img {height: 197px; max-width: 130px; }
    .user-log > img {width: 67px!important;height: 67px!important;}
    .user-log > .iconsss2 {padding: 3px!important;font-size: 23px!important; }
    .user-add {line-height: 97px!important; }
    .nav-tabssss svg { margin-right: 0px;height: 13px;}
    .central-meta {padding: 17px 7px 17px 7px;}
    .nav-tabssss li {white-space: unset;line-height: 27px;font-size: 16px;margin-bottom: 17px;}
    .central-metazzz .col-md-4{ overflow: hidden;}
    .text-warning2 {font-size: 20px;line-height: 27px;}
    .pagsss .book-wrapper img {height: 187px; max-width: 177px; }
    .videosss {width: 477px;height: 267px;}
    .yyyview img {width: 127px;height: 127px;}
    .addbookssspacks .book-wrapper img {max-width: 127px; height: 140px;}




}
@media (min-width: 992px) and (max-width:1300px){ 
ul.main-menu>li.menu-item>a { margin-left: 10px;  margin-right: 0px;}
.zzzss a { margin: 0 3px 0 3px;}

}

