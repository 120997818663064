.login-1 {
       /* background: rgba(0, 0, 0, 0.04) url(./Img/bg-image.jpg) top left no-repeat; */
       background-size: cover;
       background-size: cover;
    width: 100%;
    opacity: 1;
    min-height: 100vh;
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 0 60px 0;
    /* background: linear-gradient(0deg, #edf2f6 27%, #ffffff00 103%) repeat scroll 0 0; */
    background-color: #edf2f6;
   } 
   
   .loaderbuttonsss {
    color: #00bfff;
    height: 77px;
    width: 100%;
    float: left;
    margin-right: 0;
    display: block;
    clear: both;
    text-align: left;
}
   .login-forms label {
       float: left;
       color: #535165;
   }
   .form-button {
       border: medium none;
       border-radius: 10px;
       color: #fff;
       float: left;
       font-size: 17px;
       font-weight: 500;
       padding: 10px 30px;
       transition: all 0.2s linear 0s;
       background: #fa6342;
   }
   .h1, h1 {
    font-size: 2.0rem;
}
   .login-forms h1 {
       font-size: 2.0rem;
       color: #535165;
       float: left;
       display: block;
       width: 100%;
       text-align: left;
       margin-bottom: 37px;
   }
   .login-forms .form-control {
       padding: 27px 10px 27px 10px;
       font-size: 14px;
       border: medium none;
       border-radius: 13px;
   }
   .login-forms h6 {
       float: left;
       cursor: pointer;
   }
   .login-forms p{
       font-size: 14px;
       letter-spacing: 0.1px;
       line-height: 26px;
       color: #959ab5;
       text-align: left;
   }
   .login-1 h6 {
       cursor: pointer;
       clear:both;
   }
   .total {
    display: inline-block;
    text-align: center;
    width: 100%;
}

.total > span {
    color: #535165;
    display: inline-block;
    font-weight: 500;
    margin-top: 10px;
    text-transform: capitalize;
    width: 100%;
}
.total > em {
    color: #34465d;
    font-size: 16px;
    font-style: normal;
}
.iconsss {
    border-radius: 100%;
    color: #fff;
    display: inline-block;
    font-size: 22px;
    height: 70px;
    line-height: 70px;
    width: 70px;
    background: #fa6342;
}
.logsss {
    padding: 0;
    margin-top: 67px;
}
.logsss2{
    border-right: 1px solid #ccc;
    padding: 0 75px;
}
.logsss2 h1{ 
    text-align: left;
    color: #535165;
}
.logsss2 p{
    color: #959ab5;
    font-size: 14px;
    text-align: left;
}
.logsss3{
    padding: 0 0px 0 77px;
}
.log-user {
    display: inline-block;
    margin-top: 20px;
    width: 100%;
}
.user-log {
    display: inline-block;
    text-align: center;
    width: 100%;
    position: relative;
}
.user-log > img {
    border: 5px solid #fff;
    border-radius: 100%;
    width: 117px;
    height: 117px;
    object-fit: contain;
    object-position: center;
}
.user-add {
    background: #fff none repeat scroll 0 0;
    border: 1px dashed #afafaf;
    border-radius: 5px;
    display: block;
    line-height: 137px;
    text-align: center;
    width: 100%;
    cursor: pointer;
}
.user-add > div {
    display: inline-block;
    line-height: initial;
    vertical-align: middle;
    width: 100%;
}
.user-add > div > span {
    display: inline-block;
    margin-top: 10px;
    width: 100%;
}
.user-log > .iconsss2:hover {
    color: #fff;
}
.user-log > .iconsss2 {
    background: #fff none repeat scroll 0 0;
    border-radius: 20px;
    cursor: pointer;
    left: -7px;
    padding: 6px;
    position: absolute;
    top: -7px;
    width: auto;
    font-size: 37px;
    font-weight: 300;
    color: #fa6342;
}
.form-row {
    display: block;
}